/* BillingInvoice Component Styles */
.billing-invoice {
    font-family: 'Arial', sans-serif;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 2px solid var(--ProjectColor);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  /* Header Styles */
  .invoice-header {
    text-align: right;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .invoice-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--labelcolor);
  }
  
  /* Logo and Medical Center Address Styles */
  .invoice-logo-address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .flex-logo-name {
    display: flex;
    gap: 10px;
  }
  
  .logo img {
    max-width: 100px; /* Adjust the logo image size as needed */
    height: auto;
  }
  
  .address {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-left: 20px;
  }
  
  .addr2 {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-left: 90px;
    padding-top: 20px;
    padding-bottom: 40px;
    /* border-bottom: 4px solidvar(--ProjectColor); */
  }
  
  .addr3 {
    flex-grow: 1;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 40px;
  }
  
  .medical-center-name {
    font-size: 18px;
    font-weight: bold;
    color:var(--labelcolor);
  }
  
  .full-address {
    font-size: 14px;
    color: #666;
  }
  
  /* Invoice Details Styles */
  .invoice-details {

    margin-top: 20px;
    padding-top: 20px;
  }
  
  .invoice-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .item-name,
  .item-quantity,
  .item-price,
  .item-total {
    flex-basis: 25%;
    text-align: left;
  }
  
  .bill-to1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sec-clm {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid var(--ProjectColor);
  }
  
  .invoce-lab-span {
    display: flex;
    gap: 20px;
    text-align:left;
    align-items: left;
    justify-content: left;
  }
  
  .invoce-to2 {
    display: flex;
    row-gap: 25px;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    align-items: flex-start;
  }

  /* Table Styles */
.invoice-details table {
    width: 100%;
    border-collapse:separate;
    margin-top: 20px;
  }
  
  .invoice-details th, .invoice-details td {
    padding: 10px;
    /* margin: 5px; */
    text-align: center;
    border-bottom: 1px solid #ccc;
  }
  
  /* Table Header Styles */
  .invoice-details th {
    background-color:var(--ProjectColor);
    color: var(--projectwhite);
    border-radius: 5px;

    
 
  }
  
  /* Table Row Styles */
  .invoice-details tbody tr:nth-child(even) {
    background-color: rgba(224, 221, 223, 0.384);
  }
  
  /* Hover Styles */
  .invoice-details tbody tr:hover {
    background-color: var(--ProjectColor);
  }

  /* Total, Paid Amount, and Balance Due Styles */
.invoice-details {
   border-top: 2px solid var(--ProjectColor);
    margin-top: 20px;
    padding-top: 20px;
  }
  
  .total-section,
  .paid-amount-section,
  .balance-due-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .total-section span,
  .paid-amount-section span,
  .balance-due-section span {
    flex-basis: 50%;
    text-align:center;
  }
  
  .total-section span:last-child,
  .paid-amount-section span:last-child,
  .balance-due-section span:last-child {
    color:var(--labelcolor);
    font-weight: bold;
  }

  .delet-invce{
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  .print-btn-invc{
         display: flex;
         position: relative;
         left:40%;
         text-align: center;
         justify-content: center;
         align-items: center;
         cursor: pointer;
  }
  .bilg-ad-btn{
    border: none;
    outline: none;
    color: var(--labelcolor);
    background-color: transparent;
    cursor: pointer;
  }

  .add-data-eql{
    display: flex;
    gap: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }


  @media print {
    @page {
      size: A4;
      margin:5mm 20mm 5mm 0mm;
    }

    body {
      margin:5mm 20mm 5mm 0mm; /* Add margin to the body for better appearance */
    }
  
    .billing-invoice {
      max-width: 100%; /* Make sure the content fits within the page */
      margin: 0;
      padding: 10mm; /* Adjust padding for better appearance */
      border: none; /* Remove border for better appearance */
      box-shadow: none; /* Remove box shadow for better appearance */
    }
  
    .invoice-details table {
      width: 100%;
    }
  
    .invoice-details th, .invoice-details td {
      padding: 8px; /* Adjust padding for better appearance */
    }
  
    .invoice-details tbody tr:hover {
      background-color: transparent; /* Remove hover effect for better appearance */
    }
  
    .print-btn-invc {
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 20px; /* Adjust the distance from the bottom */
      left: 50%;
      transform: translateX(-50%);
    }
  
 
  
    /* Add any additional print-specific styles here */
  }
  
  /* Responsive Styles (Adjust as needed) */
  @media (max-width: 600px) {
    .billing-invoice {
      padding: 10px;
    }
  
    .invoice-logo-address {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .address {
      margin-top: 10px;
      margin-left: 0;
    }
  }
  