
.what-does-it-mean {
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    font-weight: bold;
  }
  
  .result-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 200px;
    gap: 10px;
  }
  
  .color-box {
    width: 30px;
    height: 30px;
  
  }
  
  .color-green {
    background-color: green;
    width: 40px;
    height: 25px;

  }
  
  .color-orange {
    background-color: orange;
    width: 40px;
    height: 25px;
  }
  
  .color-yellow {
    background-color: yellow;
    width: 40px;
    height: 25px;
  }
  
  .color-red {
    background-color: red;
    width: 40px;
    height: 25px;
  }
  

  .jfnn6k9{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .card_7juj_head {
    display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
    row-gap: 40px;
    padding: 20px;
  }
  
  .card_7juj {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
    height: 135px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card_7juj h4 {
    margin-bottom: 25px;
    color: var(--ProjectColor);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .card_7juj input[type="range"] {
    width: 100%;
  }
  

  .cmwjdjwsud66{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 45px;
    align-items: center;
  }

  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #e0e0e0;
    outline: none;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: #e0e0e0;
    border-radius: 5px;

  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
    margin-top: -4px;
  }
  
  input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }


  

  .color-box-container{
    width: 100%;
  }
  .start-value, .end-value {
    font-size: 12px;
    margin: 0 5px;
  }
  
  .percentage {
    position: absolute;
    right: 5px;
    font-size: 12px;
  }
  
  .color-box {
    height: 30px;
  }
  
  

  .white-blood-cell-count-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    flex-direction: column;
  
  }
  
  .range-values {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    width: 100%;
  }
  
  .start-value, .end-value {
    font-weight: bold;
  }
  
  .color-box-container {
    position: relative;
    height: 30px;
    width: 100%;
 
  }
  
  .color-box {
    height: 100%;
    position: relative;
  }
  
  /* .arrow-icon34f {
    position: absolute;
    top: -10px;
    font-size: 20px;
  } */
  
  .current-value {
    position: absolute;
    top: 30px;
    font-weight: bold;
  }
  
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  
  .color-name {
    font-weight: bold;
  }
  


  .ncdjjdu88{
    padding: 3px 0px;
  }



  /* .arrow-icon34f {
    position: absolute;
    top: -18px;
    font-size: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
   */

   .spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(77, 77, 77, 0.63); /* Semi-transparent black background */
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .spinner {
    margin: 0 auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
  }
  
  .spinner > div {
    background-color: white;
    height: 100%;
    width: 6px;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4); }  
    20% { -webkit-transform: scaleY(1.0); }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
  
  