/*  Header_comp csss */
.Header_comp {
    
  background-color: #01041e;
    color: var(--projectwhite) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    position: fixed;
    z-index: 999;

}

.Header_comp_1 {
    width: auto;
}

.Header_comp_icon {
    cursor: pointer;
    display: inline-block;
    animation-duration: 0.4s;

}

.Header_comp_icon.open {
    animation-name: rotateOpen;
}

.Header_comp_icon.close {
    animation-name: rotateClose;
}

@keyframes rotateOpen {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(90deg);
        /* Adjust the angle as needed */
    }
}

@keyframes rotateClose {
    from {
        transform: rotate(90deg);
        /* Start from the rotated state */
    }

    to {
        transform: rotate(0deg);
    }
}

.Header_comp_2 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

/* .Header_comp_2_img {
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    background-color: transparent;
    overflow: hidden;
}

.Header_comp_2_img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    mix-blend-mode: initial;
    box-shadow: 0 0 10px rgb(0 0 0 / 31%);
    clip-path: circle(50%);
    aspect-ratio: 3/2;

} */


 

.Header_comp_2_img {
  width: 162px;
  height: 53px;
  display: grid;
  place-items: center;
  background-color: white; /* Solid background for visibility */
  border-radius: 5px; /* Optional rounded corners */
  overflow: hidden;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  animation: glow 2s infinite alternate; /* Glowing effect */
}

.Header_comp_2_img img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures full visibility */
  mix-blend-mode: normal;
  animation: float 3s infinite ease-in-out; /* Floating effect */
}



.Header_comp_2 span {
    font-size: clamp(12px, 2vw, 24px);
    font-weight: 600;
    color: white;
}
.Header_comp_3{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.Header_comp_3_loc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
}

.Header_comp_3_loc_icon {
    font-size: clamp(20px, 1.5vw, 24px);
}

.Header_comp_3_loc_select {
    padding: 3px 10px;
    background-color: var(--ProjectColor);
    font-size: 13px;
    color: var(--labelcolor);
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: 0px;
    height: 25px;
}
.Header_comp_3_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 31%);

}




.Header_comp_3_img img {
    width: 80%;
    height: 70%;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: darken;

}

.toggled_box_header {
    width: 140px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    background-color: var(--ProjectColor);
    position: absolute;
    top: 75px;
    right: 0px;
    font-size: 12px;
  }

  @media screen and (max-width: 450px) {
  .name_of_the3{
    display: none;
  }

  .Header_comp_3_loc_select {
    padding: 3px 4px;
    background-color: var(--ProjectColor);
    font-size: 11px;
    color: var(--labelcolor);
    border: 1px solid white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    outline: 0px;
    height: 25px;

  }
  .Header_comp_3_loc{
    gap: 3px;
  }

  .Header_comp_3_img,.Header_comp_2_img img{
width: 45px;
height: 45px;
  }

}


.menu-link-wrapper {
    width: 43px;
    height: 45px;
    cursor: pointer;
  
  
  }
  
  .css-zylse7-MuiButtonBase-root-MuiIconButton-root{
    padding: 2px !important;
    
  }
  .menu-link {
    width: 100%;
    height: 100%;
  }
  
  .lines {
    -moz-transition: background 0.2s ease 0.4s;
    -o-transition: background 0.2s ease 0.4s;
    -webkit-transition: background 0.2s ease;
    /* -webkit-transition-delay: 0.4s; */
    transition: background 0.2s ease 0.4s;
    display: block;
    width: 100%;
    height: 2px;
    background: white; /* Set to white */
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    width: 60%;
    margin: 0 20%;
    position: relative;
    color: white;
    top: 22px;
  }
  
  .lines:before,
  .lines:after {
    display: block;
    width: 100%;
    height: 2px;
    background: white; /* Set to white */
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    position: absolute;
    left: 0;
    content: '';
    color: white;
    -moz-transition: -moz-transform 0.2s ease 0s, width 0.2s ease 0.2s, top 0.2s ease 0.4s;
    -o-transition: -o-transform 0.2s ease 0s, width 0.2s ease 0.2s, top 0.2s ease 0.4s;
    -webkit-transition: -webkit-transform 0.2s ease, width 0.2s ease, top 0.2s ease;
    -webkit-transition-delay: 0s, 0.2s, 0.4s;
    transition: transform 0.2s ease 0s, width 0.2s ease 0.2s, top 0.2s ease 0.4s;
    -moz-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
  }
  
  .lines:before {
    top: -7px;
  }
  
  .lines:after {
    top: 7px;
  }
  
  .menu-trigger-open .lines {
    -moz-transition: background 0.2s ease 0s;
    -o-transition: background 0.2s ease 0s;
    -webkit-transition: background 0.2s ease;
    -webkit-transition-delay: 0s;
    transition: background 0.2s ease 0s;
    background: white;
  }
  
  .menu-trigger-open .lines:before,
  .menu-trigger-open .lines:after {
    -moz-transition: top 0.2s ease 0s, width 0.2s ease 0.2s, -moz-transform 0.2s ease 0.4s;
    -o-transition: top 0.2s ease 0s, width 0.2s ease 0.2s, -o-transform 0.2s ease 0.4s;
    -webkit-transition: top 0.2s ease, width 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-transition-delay: 0s, 0.2s, 0.4s;
    transition: top 0.2s ease 0s, width 0.2s ease 0.2s, transform 0.2s ease 0.4s;
    top: 0;
    width: 50%;
    background: white;
  }
  
  .menu-trigger-open .lines:before {
    -moz-transform: rotate3d(0, 0, 1, 45deg);
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
  }
  
  .menu-trigger-open .lines:after {
    -moz-transform: rotate3d(0, 0, 1, -45deg);
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
  }