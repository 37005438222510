
  .invcontainer {
    width: 90%;
    padding: 20px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .iiiioucon {
    margin-top: 20px;
  }
  
  .eetable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .monthHeader {
    background-color: #ffeb3b;
    color: #000;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    border: 1px solid #ccc;
  }
  
  .secHead {
    background-color: #2e7d32;
    color: white;
    text-align: center;
    font-weight: bold;
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .comnHea {
    background-color: #388e3c;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    border: 1px solid #ccc;
  }
  
  .rree {
    border: 1px solid #ccc;
    padding: 8px;
  }
  
  .rac {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: right;
  }
  
  .cenACll {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }
  
  .boldRow {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  