/* InventoryPurchase.css */
.PurchaseRaise_Master_container{
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.PurchaseRaise_Master_header {
    background-color: var(--ProjectColor);
    padding: 5px;
    color: black;
    border-radius: 5px;
    margin: 5px;
    width: 99%;
    box-sizing: border-box;
    height: 40px;

}

.PurchaseRaise_Master_header_h3 {
    margin: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

}
.PurchaseRaise_Master_Con_1{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
}
.PurchaseRaise_Master_form_div {
    width: 19%;
}
.PurchaseRaise_Master_form_div_label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 13px;
    color: var(--ProjectColor);
}

.PurchaseRaise_Master_form_div_input {
    width: 83%;
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 30px;
    font-size: 12px;
    margin: 0px;
}

.PurchaseRaise_Master_form_div_text {
    width: 80%;
    padding: 0px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 28px;
    font-size: 12px;
}

.btn-add {
    background-color: var(--ProjectColor);
    border-radius: 5px;
    width: 100px;
    border: none;
    height: 30px;
    align-items: center;
}

.button_buton_class {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.btn-add:hover {
    background-color:var(--ProjectColorhover);
    cursor: pointer;
}
.Stoct_Product_master_form_table_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.Stoct_Product_master_form_table_container_11{
    width: 90%;
    overflow: auto;
}
.Stoct_Product_master_form_table_container_11 table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.Stoct_Product_master_form_table_container_11 table th,
.Stoct_Product_master_form_table_container_11 table td {
    border: 1px solid #ddd;
    padding:  5px 0px;
    font-size: 12px;
    text-align: center;
}



.Stoct_Product_master_form_table_container_11 table th {
    background-color: var(--ProjectColor);
    color: black;
    font-size: 11px;
}
.Add_items_Purchase_Master{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color:grey;
    font-size: 15px;
    font-weight: 600;
    height: 30px;
  }

  .choose-file-button-99 {
    display: inline-block;
    font-size: 11px;
    padding: 5px 8px !important;
    background-color: var(--ProjectColor);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    width: 64px !important;
  }

/* Media queries for responsiveness */

@media (max-width: 768px) {
    .PurchaseRaise_Master_form_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PurchaseRaise_Master_container {
        align-items: center;
        justify-content: center;
    }

    
    .PurchaseRaise_Master_form_div_input {
        width: 50%;
    }
    .PurchaseRaise_Master_form_div_text {
        width: 50%;
        box-sizing: border-box;
    
    }
    .PurchaseRaise_Master_header {
        background-color: var(--ProjectColor);
        padding: 5px;
        color: black;
        border-radius: 5px;
        margin: 5px;
        width: 95%;
        box-sizing: border-box;
        height: 40px;

    }

    .PurchaseRaise_Master_form_div_label {
        display: flex;
        margin: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 13px;
        color: var(--ProjectColor);
    }


}