ex-start.tablewidth {
  width: 700px;
}

.Addnamebtn {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  height: 30px;
  width: 20px;
}
.Table_edit_btn {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  border: none;
}
.Savebtn_class {
  text-align: center;
  margin-top: 20px;
}

.Savebtn_class button {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: 6px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 20px;
}
.form-row11 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}


.Check_box_testname_sub_con{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  font-size: var(--fontsize);
  font-weight: bold;
  gap: 10px;
}
.Check_box_testname_sub_con label{
  display: flex;
  gap: 5px;
 width: 100px;
 justify-content: space-between;
 align-items: center;
 text-align: flex-start;
 padding: 5px;
}

.Check_box_testname{
  display: flex;
  gap: 100px;
  row-gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}

.Check_box_testname_sub h4{
  padding: 10px;
}

.Check_box_testname_sub343{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.disabled {
  opacity: 0.5; /* Reduce opacity to make the button appear disabled */
  cursor: not-allowed; /* Change cursor to indicate not clickable */
}