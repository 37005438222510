/* Sidebar container */
.Sidebar_container_con {
  position: fixed;
  top: 59px;
  left: 0;
  width: 60px; /* Default collapsed width */
  height: calc(100% - 60px);
  /* background-color: var(--opacityColor);
   */
 
  overflow: auto;
  transition: width 0.3s ease, left 0.3s ease;
  z-index: 1000 !important;
}

.Sidebar_container_con::-webkit-scrollbar {
  display: none;
}



.Sidebar_container_con {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

/* When sidebar is expanded */
.Sidebar_container_con.expanded {
  width: 250px;
}

/* When sidebar is collapsed */
.Sidebar_container_con.collapsed {
  width: 50px;
}

/* Hide scrollbar */
.Sidebar_container_con::-webkit-scrollbar {
  display: none;
}

.Sidebar_container_con {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Sidebar menu styles */
.Sidebarmenuhover {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color:  rgb(18, 18, 18);
  ;
}

.Sidebarmenuhover > li {
  padding: 20px 15px;
  width: 250px; /* Ensure this matches the expanded width */
  cursor: pointer;
  display: flex;
  /* background-color: var(--opacityColor);
   */
   background-color: #002953;
  border-bottom: 1px solid rgba(191, 180, 180, 0.85);
  gap: 10px;
  align-items: center;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  font-size: 14px;
}

.Sidebarmenuhover > li:hover {
  background-color: #01041e;
  padding-left: 18px;
}

.Sidebarmenuhover > li.active_act {
  background-color: #01041e;
  padding-left: 25px;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
}


/* Change icon and arrow color when active */
.Sidebarmenuhover > li.active_act .inventory_sidebar_icon,
.Sidebarmenuhover > li.active_act .arrow-icon {
  color: var(--yellowcolorDubai); /* Set to desired color when active, e.g., white */
}

/* Rotate arrow when active */
.Sidebarmenuhover > li .arrow-icon {
  transition: transform 0.3s ease;
}

.Sidebarmenuhover > li.active_act .arrow-icon.arrow-rotate {
  transform: rotate(180deg); /* Rotate the arrow */
  color: var(--yellowcolorDubai); /* Set to the same color as the icon when active */
}


/* Tooltip and icon styles */
.icon_tooltip {
  display: flex;
  align-items: center;
}

.icon_tooltip .icon-name {
  margin-left: 10px;
  font-weight: bold;
  color: white;
}

.icon_tooltip .arrow-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.arrow-rotate {
  transform: rotate(180deg);
}

/* Sub-menu styles */
.subSidebarmenuhover {
  list-style-type: none;
  padding: 0;
  border-radius: 5px;
  margin: 7px 5px 7px 40px;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}


.subSidebarmenuhover.show {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.subSidebarmenuhover.show > li {
  animation: slideIn 0.5s ease forwards;
}

.subSidebarmenuhover.show > li:nth-child(1) {
  animation-delay: 0.1s;
}
.subSidebarmenuhover.show > li:nth-child(2) {
  animation-delay: 0.2s;
}
.subSidebarmenuhover.show > li:nth-child(3) {
  animation-delay: 0.3s;
}
.subSidebarmenuhover.show > li:nth-child(4) {
  animation-delay: 0.4s;
}
.subSidebarmenuhover.show > li:nth-child(5) {
  animation-delay: 0.5s;
}
.subSidebarmenuhover.show > li:nth-child(6) {
  animation-delay: 0.6s;
}
.subSidebarmenuhover.show > li:nth-child(7) {
  animation-delay: 0.7s;
}

.subSidebarmenuhover.show > li:nth-child(8) {
  animation-delay: 0.8s;
}
.subSidebarmenuhover.show > li:nth-child(9) {
  animation-delay: 0.9s;
}
.subSidebarmenuhover.show > li:nth-child(10) {
  animation-delay: 1s;
}
.subSidebarmenuhover.show > li:nth-child(11) {
  animation-delay: 1.1s;
}

.subSidebarmenuhover.show > li:nth-child(12) {
  animation-delay: 1.2s;
}
.subSidebarmenuhover.show > li:nth-child(13) {
  animation-delay: 1.3s;
}
.subSidebarmenuhover.show > li:nth-child(14) {
  animation-delay: 1.4s;
}

.subSidebarmenuhover.show > li:nth-child(15) {
  animation-delay: 1.5s;
}
.subSidebarmenuhover.show > li:nth-child(16) {
  animation-delay: 1.6s;
}
.subSidebarmenuhover.show > li:nth-child(17) {
  animation-delay: 1.7s;
}

.subSidebarmenuhover.show > li:nth-child(18) {
  animation-delay: 1.8s;
}
.subSidebarmenuhover.show > li:nth-child(19) {
  animation-delay: 1.9s;
}
.subSidebarmenuhover.show > li:nth-child(20) {
  animation-delay: 2s;
}


/* Keyframes for sliding in from left */
@keyframes slideIn {
  from {
    transform: translateX(-120px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.subSidebarmenuhover > li {
  padding: 12px 9px;
  margin: 2px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13.5px;
  text-align: start;
  color: black;
  background-color: var(--yellowcolorDubai);
  border-bottom: 1px solid white;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
  opacity: 0;
}

.subSidebarmenuhover > li:hover {
  background-color: var(--ProjectColorhover);
  padding-left: 18px;
}

.inventory_sidebar_icon {
  font-size: 18px;
  color: white;
}



/* Main content area */
.Main_container_content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  background-color: white;
  padding: 7px;
  width: 100%;
  margin-top: 60px;
  overflow-y: auto;
  height: calc(100vh - 110px);
}


.sidebar.collapsed ~ .Main_container_content {
  margin-left: 50px;
}

/* Footer */
.Main_container_Footer {
  width: 100%;
  height: 30px;
  background-color: #f1f1f1;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  padding: 10px;
  color: #333;
}

/* Remove default scrollbar appearance */
.Main_container_content::-webkit-scrollbar {
  background-color: transparent;
  width: 0 !important;
}


/* Hide sidebar on screens 450px or less */
@media screen and (max-width: 450px) {
  .sidebar.collapsed {
    display: none; /* Completely hide the sidebar */
  }

  .Main_container_content {
    width: 100%;
    margin-left: 0; /* Make sure the content takes full width */
  }

  .sidebar.expanded {
    display: block;
    width: 250px;
    position: fixed;
    top: 59px;
    left: 0;
    height: 100%;
    z-index: 1000; /* Ensure it appears on top when toggled */
  }

  .Main_container_content {
    width: 100%;
    margin-left: 0;
    
  }

  .sidebar.collapsed ~ .Main_container_content{
    margin-left: 0px;
  }
  /* Adjust margin based on sidebar state */
.sidebar.expanded ~ .Main_container_content {
  margin-left: 0px; /* Margin for expanded sidebar */
}
.Sidebar_container_con.collapsed ~ .Main_container_content {
  margin-left: 0; /* No margin for content when sidebar is hidden */
}

.Sidebar_container_con.expanded ~ .Main_container_content {
  margin-left: 0; /* No margin for content when sidebar is expanded */
}



}