.Supplier_Master_form_Con_div_Row_inputtiltle {
  width: 23%;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.Supplier_Master_form_Con_div_Row_title_name{
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  gap: 5px;
  align-items: center;
}
.santhu_billing{
  padding: 20px !important;
}
input.Supplier_Master_form_Con_div_Row_input11 {


  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
padding: 5px;
}
.Supplier_Master_form_Con_div_Row_input211{
  width: 70%;
  height: 22px;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 10px;
  outline: none;
}
.searching_input_icon_billing{
  background-color: transparent;
  border: none;
  outline: none;
}

@media screen and(max-width: 450px){

  .Supplier_Master_form_Con_div_Row_input211 {
  width: 49%;
  height: 22px;
  border-radius: 5px;
  border: 1px solid grey;
  outline: none;
 }
}

@media screen and (max-width: 768px) {
  .Supplier_Master_form_Con_div_Row_inputtiltle{
    width: 15%;
  }

  .Supplier_Master_form_Con_div_Row_title_name{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Supplier_Master_form_Con_div_Row_input211{
    width: 55%;
    height: 22px;
    border-radius: 5px;
    border: 1px solid grey;
    outline: none;
  }
}

@media print{
  @page {
    size: A4;
    margin: 10px !important;
    padding: 10px !important;
    /* margin-top: 40px !important; */
    /* margin-bottom: 40px; */
}

}